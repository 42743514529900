// keys
export const PRIMARY = 'primary';
export const HEADLINE = 'headline';
export const DESCRIPTION = 'description';
export const MEDIA = 'media';
export const BIDDING_CONTROL = 'biddingControl';
export const CTA = 'cta';
export const DAILY_BUDGET = 'dailyBudget';
export const AUDIENCES = 'audiences';
export const FB_PAGE = 'facebookPage';

// CTA OPTIONS
export const NO_BUTTON = 'NO_BUTTON';
export const LEARN_MORE = 'LEARN_MORE';
export const SHOP_NOW = 'SHOP_NOW';
export const PLAY_GAME = 'PLAY_GAME';
export const ORDER_NOW = 'ORDER_NOW';
export const GET_OFFER = 'GET_OFFER';

export const CTA_OPTIONS = [
  { value: NO_BUTTON, text: 'no button' },
  { value: LEARN_MORE, text: 'learn more' },
  { value: SHOP_NOW, text: 'shop now' },
  { value: PLAY_GAME, text: 'play game' },
  { value: ORDER_NOW, text: 'order now' },
  { value: GET_OFFER, text: 'get offer' },
];

const RANGE = {
  STANDARD: 3,
  EXTENDED: 6,
  FULL: 12,
};

export const getIterationOptions = mode => {
  const hasCBO = mode === 'FULL';

  const iterationDesc = (i, x) => {
    const y = x + RANGE[mode] - 1;

    return `${i} - Strategy ad set range from ${x} to ${y}
    ${hasCBO ? ` and cbo from 1${x < 10 ? `0${x}` : x} to 1${y}` : ''}`;
  };

  return [
    { value: 1, text: iterationDesc(1, 1) },
    { value: 2, text: iterationDesc(2, 13) },
    { value: 3, text: iterationDesc(3, 25) },
    { value: 4, text: iterationDesc(4, 37) },
    { value: 5, text: iterationDesc(5, 49) },
  ];
};

// Google fields

export const MARKETING_IMAGES = 'marketingImages';
export const SQUARE_MARKETING_IMAGES = 'squareMarketingImages';
export const MARKETING_VIDEOS = 'videos';

export const GOOGLE_FIELDS = {
  HEADLINES: ['headlineOne', 'headlineTwo', 'headlineThree'],
  DESCRIPTIONS: ['descriptionOne', 'descriptionTwo'],
  LONGHEADLINES: ['longHeadline'],
  KEYWORDS: ['keywords'],
  MEDIA: [MARKETING_IMAGES, SQUARE_MARKETING_IMAGES, MARKETING_VIDEOS],
};

export const CODE_LIMITS = {
  lookalikeCode: 4,
  cboLookalikeCode: 9,
};

// Strategy options
export const STRATEGY_ONE = 'S-1';
export const STRATEGY_TWO = 'S-2';
export const STRATEGY_THREE = 'S-3';

export const STRATEGY_OPTIONS = [
  { value: STRATEGY_ONE, text: 'Strategy 1' },
  // { value: STRATEGY_TWO, text: 'Strategy 2' },
  { value: STRATEGY_THREE, text: 'Strategy 3' },
];

export const STRATEGY_SEARCH = 'SEARCH';
export const STRATEGY_BRANDED_SEARCH = 'BRANDED_SEARCH';
export const STRATEGY_PERFORMANCE = 'PERFORMANCE';

export const GOOGLE_STRATEGY_OPTIONS = [
  { value: STRATEGY_SEARCH, text: 'Search' },
  { value: STRATEGY_BRANDED_SEARCH, text: 'Branded Search' },
  { value: STRATEGY_PERFORMANCE, text: 'Performance' },
];

// Form types
export const FORM = {
  FB: 'facebook',
  GOOGLE: 'google',
};

// UI strings
export const UI = {
  STRATEGY: 'Strategy',
  AD_ACCOUNT: 'FB Ad Account',
  PRIMARY: 'Primary Text',
  HEADLINE: 'Headline',
  LONG_HEADLINE: 'Long Headline',
  DESCRIPTION: 'Description',
  MEDIA: 'Media',
  UPLOAD: 'Upload',
  LAUNCH: 'Launch',
  BIDDING_CONTROL: 'Bidding Amount',
  CTA: 'Call to Action',
  DAILY_BUDGET: 'Daily Budget',
  SETTINGS: 'Campaign settings',
  CAMPAIGN_STATUS: 'Create campaigns as live',
  FB_PAGE: 'Facebook Page',
  ADSET_BUDGET: 'AdSet budget for every ad',
  MODE: 'Mode',
  AUDIENCE_SIZE: 'Audience Size',
  SCHEDULE: 'Scheduled',
  TOOLTIPS: { SCHEDULE: 'Adsets will be scheduled to the project start date' },
  KEYWORDS: 'Keywords',
  TOP_COUNTRIES: 'Top Countries',
  LOOKALIKE: 'Lookalike code',
  CBO_LOOKALIKE: 'CBO lookalike code',
  ITERATION: 'Iteration',
};
